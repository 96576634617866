<template>
  <div class="course container is-fullhd">
    <h1 class="title">{{title}}</h1>
    <section class="columns">

      <div class="column is-three-quarters">
        <p class="subtitle" v-html="subtitle"></p>
        <CardList :items="skills" :url="'/course/' + url" :showProgress="true" />
      </div>

      <Sidebar class="column" v-if="loggedIn" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';
import Sidebar from '@/components/Sidebar.vue';
import CardList from '@/components/CardList.vue';

export default {
  name: 'Course',
  components: {
    Sidebar,
    CardList,
  },
  data() {
    return {
      url: '',
      loading: true,
      skills: [],
    };
  },
  created() {
    this.url = this.$route.params.courseUrl;
    this.getCourse();
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
      loggedIn: 'session/loggedIn',
    }),
    course() {
      if (this.url) {
        return this.$store.getters['site/course'](this.url)[0];
      }
      return null;
    },
    title() {
      return this.course ? this.course.name : 'Course';
    },
    subtitle() {
      return this.course ? this.course.description : 'Loading...';
    },
  },
  methods: {
    async getCourse() {
      try {
        const res = await API.courses.find(this.url);
        this.skills = res.data.course.skills;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.loading = false;
    },
  },
};
</script>
