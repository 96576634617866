<template>
  <router-link :to="url + '/' + item.url">
    <div class="card item-card"
      :class="{'is-complete': item.complete || item.progress >= 100}">
      <div class="card-image">
        <figure class="image is-square">
          <img :src="item.image" :alt="item.name">
        </figure>
      </div>
      <div class="card-content">
        <div class="content">
          <p class="has-text-weight-semibold has-text-centered">{{item.name}}</p>
          <template v-if="showProgress">
            <p v-if="item.complete || item.progress >= 100" class="has-text-centered complete">
              <span class="icon has-text-success">
                <fa-icon :icon="['fas', 'check-circle']" />
              </span>
              <span class="complete-text">Complete</span>
            </p>
            <template v-else>
              <b-progress
                v-if="item.progress !== null && item.progress !== undefined"
                type="is-success"
                :value="item.progress"
                :show-value="true"
                format="percent"
                :precision="0"
                :keep-trailing-zeroes="true"
                class="item-progress" />
              <p v-else class="has-text-centered">Not sketched yet.</p>
            </template>
          </template>
          <template v-if="showMeta">
            <div class="level">
              <div class="level-left">
                <small>{{item.experience}} XP</small>
              </div>
              <div class="level-right">
                <small>Joined {{joinedAgo}}</small>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import dayjs from '@/services/dayjs';

export default {
  name: 'GenericCourseCard',
  props: {
    item: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      default: '',
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    showMeta: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    joinedAgo() {
      if (!this.showMeta) return null;
      const ca = dayjs(this.item.dateJoined);
      const m = dayjs();
      return ca.isAfter(m) ? m.fromNow() : ca.fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.card.is-complete {
  background: rgba(100, 255, 100, 0.1);
}
.complete {
  line-height: 1;

  .complete-text {
    position: relative;
    bottom: 0.3em;
  }
}
.item-progress {
  margin-bottom: 0.5em;
}
</style>
