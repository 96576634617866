<template>
  <div class="columns card-list is-multiline">
    <div
      v-for="item in items"
      :key="'item-' + item.id"
      class="column"
      :class="columnClasses">

      <GenericCourseCard
        :item="item"
        :url="url"
        :showProgress="showProgress"
        :showMeta="showMeta" />
    </div>
  </div>
</template>

<script>
import GenericCourseCard from '@/components/GenericCourseCard.vue';

export default {
  name: 'CardList',
  components: {
    GenericCourseCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    url: {
      type: String,
      default: '',
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    showMeta: {
      type: Boolean,
      default: false,
    },
    columnClasses: {
      type: Array,
      default: () => ['is-one-third'],
    },
  },
};
</script>
